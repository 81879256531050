.c-about {

    &__title {
        display: flex;
        align-items: center;

        &:after {
            flex: 1;
            content: '';
            height: 1px;

            background: $line-colour;
        }

        h2 {
            font-weight: $font-bold;
        }
    }

    &__paragraph {
        font-weight: $font-light;
    }

    &__contact {
        background-color: $body-font-color;
        color: $body-background;
        font-size: 25px;
        font-weight: $font-light;
        font-family: $body-font-family !important;
        padding: 0;
        width: 100%;
        height: 77px;
        appearance: none;
        border: 0;
        cursor: pointer;
        color: #fff;
        padding: rem-calc(10 15);

        &:focus,
        &:hover {
            background-color: $primary-colour;


        }
    }
}