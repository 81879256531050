
.c-footer {
    background-color: $body-font-color;


    &__paragraph {
        color: $body-background;
        font-size: 16px;
    }

    &__copyright {
        color: #454545;
        font-size: 16px;
    }
}