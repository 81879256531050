.c-offer {
    min-height: 600px;
    &__container {
      
        -webkit-box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 1);
        -moz-box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 1);
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 1);
        z-index: 2;
        background-color: $body-background;
    }

    &__title {
        font-weight: $font-bold;
    }

    &__ad_tech {
        background-color: $dark-brown-colour;
        &:hover{
            background-color: #835a30;
        }
    }

    &__digital {
        background-color: $dark-brown-colour;
        &:hover{
            background-color: #835a30;
        }
    }

    &__atelier {
        background-color: $dark-brown-colour;
        &:hover{
            background-color: #835a30;
        }
    }
    &__click{
        color: $click-colour;
        &:hover{
            color: $body-font-color !important;
        }
        
    }
    &__button{
        &:hover{
            cursor: pointer;
        }
    }
    @media only screen and (max-width: 992px) {
        &__offers{
            img{
                max-width: 60%;
            }
        }
       
      }
 
  

    //OFFERS
    &__title_text{
        font-weight: $font-bold;
        background-color: $unactive-colour;
        color: $body-background;
        cursor: pointer;
        &:hover{
            background-color: $primary-colour;
        }
        &.active{
            background-color: $primary-colour;
        }
    }
    &__list{
        list-style: none;
        margin: 0px;
        &_item{
            color: $list-items;
            font-size: 20px;
            cursor: pointer;
            &:hover{
                color: $body-font-color;
                font-size: 25px;
                font-weight: 600;
            }
            &.active{
                color: $body-font-color;
                font-size: 25px;
                font-weight: 600;

             }  
        }

    }
    &__paragraph{
        font-size: 18px;
        font-weight: 300;
        opacity: 0;
        display: none;
        &.active{
            opacity:1;
            display: block;
        }
    }
    &__targeting{
        
        &_list{
            list-style: none;
            font-size: 18px;
        }
        &_item{

            &::before{
                content: "\2022";
                color: #A27342;
                font-weight: bold;
                display: inline-block; 
                width: 1em;
                margin-left: -1em;
            }
        }
    }
    &_o-row{
        display: none;
        opacity: 0;
        &.active{
            display: flex;
            opacity: 1;
        }
    }
}