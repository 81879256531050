.c-projects{
  &__title{
    font-weight: $font-bold;
      &_bg{
        background-image: url(../images/career/line.png);
        background-repeat: no-repeat; 
        background-position: center;
        background-size: contain;
      }
  }
}

.carousel {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-family: Arial;
  
    &__list {
      display: flex;
      list-style: none;
      position: relative;
      width: 100%;
      height: 300px;
      justify-content: center;
      perspective: 300px;
    }
    
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0px;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all .3s ease-in;
      
      &:nth-child(1) {
        background-image: url(../images/projects/2.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

      }
      &:nth-child(2) {
        background-image: url(../images/projects/5.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

      }
      &:nth-child(3) {
        background-image: url(../images/projects/6.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

      }
     
      
      &[data-pos="0"] {
        z-index: 5;
      }
      
      &[data-pos="-1"],
      &[data-pos="1"] {
        opacity: 0.7;
        filter: blur(1px) grayscale(10%);
      }
      
      &[data-pos="-1"] {
        transform: translateY(-40%) scale(.9);
        filter: blur(1px) grayscale(10%);
        z-index: 4;
      }
      
      &[data-pos="1"] {
        transform: translateY(40%) scale(.9);
        filter: blur(1px) grayscale(10%);
        z-index: 4;
      }
      
    }
  }

  .carousel2 {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    font-family: Arial;
  
    &__list {
      display: flex;
      list-style: none;
      position: relative;
      width: 100%;
      height: 300px;
      justify-content: center;
      perspective: 300px;
    }
    
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0px;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all .3s ease-in;
      
      &:nth-child(1) {
        background-image: url(../images/projects/1.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

      }
      &:nth-child(2) {
        background-image: url(../images/projects/4.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

      }
      &:nth-child(3) {
        background-image: url(../images/projects/3.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

      }
     
      
      &[data-pos="0"] {
        z-index: 5;
      }
      
      &[data-pos="-1"],
      &[data-pos="1"] {
        opacity: 0.7;
        filter: blur(1px) grayscale(10%);
      }
      
      &[data-pos="-1"] {
        transform: translateY(-40%) scale(.9);
        filter: blur(1px) grayscale(10%);
        z-index: 4;
      }
      
      &[data-pos="1"] {
        transform: translateY(40%) scale(.9);
        filter: blur(1px) grayscale(10%);
        z-index: 4;
      }

 
    }
  }
  .showReel_text{
    display: inline-flex;position: relative;left:20px;font-weight: bold;
  }
  .icon_reel{
    display: inline-flex;
  }
  .reel_link:focus    {
    outline: 0px;
    
  }