.c-header {
    &__container {
        background-color: #FBF8F5;
        -webkit-box-shadow: 0px 10px 10px -10px rgb(110, 110, 110);
        -moz-box-shadow: 0px 10px 10px -10px rgb(110, 110, 110);
        box-shadow: 0px 10px 10px -10px rgb(110, 110, 110);
    }

    &__logo {
        display: flex;
        align-items: center;

        &:before,
        &:after {
            flex: 1;
            content: '';
            height: 1px;
            background: $line-colour;
        }
    }

    &__banner {
        background-image: url(../images/header/banner-1.png);
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 234px;
        height: 33.021vw;
        width: 100%;
        margin: 0px;
        transition: 0.5s;
        z-index: 222;

        &:hover {
            background-image: url(../images/header/banner-2.png);
        }
        
        
    }
    &__mobile_bg{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2223;

        &_col{
            position: absolute;
            background-color: $body-background;
            width: 25%;
            height: 100%;
        }
        &_col1{
            left: 0;
        }
        &_col2{
            left: 25%;
        }
        &_col3{
            left: 50%;
        }
        &_col4{
            left: 75%;
        }
    }
   
}
.c-nav{
    &__burger{
        cursor: pointer;
    }
}
.x-close{
    position: absolute;
    z-index: 22;
    right: 0;
}
.logo-after img{
    opacity: 0;
}



