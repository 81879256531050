.c-vision {
    &__title {
        height: auto;
        margin-top: 0px;
        margin-left: 0px;
        background-image: url(../images/career/line.png);
        background-repeat: no-repeat;

        &_text {
            font-weight: $font-bold;
        }

        &_paragraph {
            font-size: 17px;
        }
    }

    &__spinner-square{
        display: flex;
        flex-direction: row;
        width: 60px;
        height: 90px;

        > .square {
            width: 17px;
            height: 50px;
            margin: auto auto;
            border-radius: 4px;
        }
    }
    


    &__joblist {
        list-style: none;
        margin: 0px;

        &_item {
            border-bottom: 1px solid #707070;
            font-size: 16px;
            margin: 0px;

        

            &:hover {
                cursor: pointer;
                color: #A27342;
            }

       
           
            span{
                display: block;
                font-size: 12px;
                color: #707070;
            }
        }
    }

    &__details {
        opacity: 0;
        display: none;

        &.active {
            display: block;
            // opacity: 1;
        }

        &__title {
            font-size: 25px;
            font-weight: $font-bold;
        }
        &__subtitle {
            font-size: 20px;
            font-weight: $font-bold;
        }
        &__loading{
            display: none;
        }

        &__paragraph {
            font-size: 18px;
        }

        &__list {
            font-size: 18px;
            font-weight: $font-bold;

            &_item {
                font-weight: $font-light;

                &::before {
                    content: "\2022";
                    color: #A27342;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }

        &__button {
            font-weight: 300;
            font-family: $font-family-monospace;
            font-size: 25px;
            background-color: $body-font-color;

            &:hover {
                background-color: $primary-colour;

            }
        }
    }
}
iframe{
    margin: 0px;
    padding: 0px;
    position: absolute;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 0px;
    background: none;
}
.showImage{
    position: relative;
}
.showImage img{
    width: 100%;
}