
button, input, optgroup, select, textarea {
    font-family: $body-font-family-nobold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
}
select {
    box-sizing: border-box;
    width: 100%;
    padding: rem-calc(5px 8px);
    height: 40px;
    border: 1px solid $list-items;
    transition: border-color 0.35s;
}
button, input[type=submit] {
    padding: 0;
    width: 100%;
    height: 77px;
    appearance: none;
    border: 0;
    font-size: 30px;
    color: #fff;
    font-weight: bold;
    font-family: 'Poppins-Bold';
    background: transparent;
    line-height: 1;
    cursor: pointer;
    background-color: $primary-colour;
    color: #fff;
    padding: rem-calc(10 15);
    font-weight: 600;
    &:focus, &:hover {
       
    }
}
fieldset {
    border: 2px solid $primary-colour;
    legend {
        padding: 0 rem-calc(10px);
        font-weight: bold;
    }
}
label {
    margin-bottom: rem-calc(5px);
    display: block;
}
input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=url], textarea {
    width: 100%;
    padding: rem-calc(20px 20px);
    height: 40px;
    border: 1px solid  $list-items;
    transition: border-color 0.35s;
    margin: rem-calc(10px 0px);
    border-radius: 10px;
}
input[type=email], input[type=text]{
    width: 100%;
    padding: rem-calc(20px 20px);
    border-radius: 10px;
    height: 40px;
    border: 1px solid  $list-items;
    transition: border-color 0.35s;
    margin: rem-calc(10px 0px);
}
textarea {
    height: auto;
    resize: none;
    height: 300px;
    width: 100%;
   
}