
$header-font-family: $body-font-family !default;
$header-font-weight: $global-weight-normal !default;
$header-font-style: normal !default;
$font-family-monospace: $body-font-family !default;
$font-family-serif: 'serif' !default;
$header-color: inherit !default;
$header-lineheight: 1.4 !default;
$header-margin-bottom: 0.5rem !default;
$small-font-size: 80% !default;

$header-small-font-color: #333333 !default;
$header-lineheight: 1;
$paragraph-lineheight: normal !default;
$paragraph-margin-bottom: 1rem !default;

$align-center: center;
$font-bold: bold;
$font-light: 300;

$header-styles: (
    small: (
        'h1': ('font-size': 16),
        'h2': ('font-size': 30),
        'h3': ('font-size': 25),
        'h4': ('font-size': 20),
        'h5': ('font-size': 20),
        'h6': ('font-size': 20),
        'p': ('font-size': 18),
        '.bottom-nav-item': ('font-size': 20),
        '.c-career__title_text': ('font-size': 30),
        '.c-contact__email': ('font-size': 12),
        '.c-contact__title_h1': ('font-size': 20),
        '.c-contact__role': ('font-size': 18),
        '.showReel_text': ('font-size': 17),



    ),
    medium: (
        'h1': ('font-size': 18),
        'h2': ('font-size': 40),
        'h3': ('font-size': 25),
        'h4': ('font-size': 22),
        'h5': ('font-size': 20),
        'h6': ('font-size': 20),
        'p': ('font-size': 18),
        '.bottom-nav-item': ('font-size': 20),
        '.c-career__title_text': ('font-size': 40),
        '.c-contact__email': ('font-size': 18),
        '.c-contact__title_h1': ('font-size': 30),
        '.c-contact__role': ('font-size': 18),
        '.showReel_text': ('font-size': 25),

    ),
    large: (
        'h1': ('font-size': 20),
        'h2': ('font-size': 40),
        'h3': ('font-size': 25),
        'h4': ('font-size': 22),
        'h5': ('font-size': 20),
        'h6': ('font-size': 20),
        'p': ('font-size': 18),
        '.bottom-nav-item': ('font-size': 20),
        '.c-career__title_text': ('font-size': 40),
        '.c-contact__email': ('font-size': 18),
        '.c-contact__title_h1': ('font-size': 40),
        '.c-contact__role': ('font-size': 18),
        '.showReel_text': ('font-size': 25),

    ),
    xlarge: (
        'h1': ('font-size': 20),
        'h2': ('font-size': 40),
        'h3': ('font-size': 25),
        'h4': ('font-size': 26),
        'h5': ('font-size': 26),
        'h6': ('font-size': 20),
        'p': ('font-size': 18),
        '.bottom-nav-item': ('font-size': 20),
        '.c-career__title_text': ('font-size': 40),
        '.c-contact__email': ('font-size': 20),
        '.c-contact__title_h1': ('font-size': 40),
        '.c-contact__role': ('font-size': 24),
        '.showReel_text': ('font-size': 25),

    ),
) !default;