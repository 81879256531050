@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

$body-background: #ffffff !default;
$body-font-family: 'Poppins';
$body-font-family-nobold: 'Poppins';
$global-weight-normal: 200;
$global-weight-bold: bold;
$global-lineheight: 1.5 !default;
$body-font-color: #000000 !default;
$base-font-size: 100% !default;
$global-width: 1400 !default;