.bottom-nav {
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.8rem 0;
    z-index: 99;
    will-change: transform;
    transform: translateZ(0);

    &-item {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        text-align: center;
        color: $body-font-color;
        font-weight: $font-bold;
        transition: all 0.3s;

        &.active::after {
            content: "";
            display: block;
            margin: auto;
            height: 3px;
            width: 35%;
            top: 5px;
            background: $nav-colour;

        }

        &:active {
            color: $primary-colour;
        }

        &:focus {
            border: none;

        }

        &:after {
            content: "";
            display: block;
            margin: auto;
            height: 3px;
            width: 0;
            top: 5px;
            background: transparent;
            transition: all 0.3s;
        }

        &:hover::after {
            width: 35%;
            background: $nav-colour;
        }
    }

    &-link {
        display: flex;
        flex-direction: column;
    }


}

.bottom-nav_mobile {
    display: block;
    position: absolute;
    top: 20vh;
    left: 0;
    right: 0;
    padding: 0.8rem 0;
    z-index: 99;
    will-change: transform;
    transform: translateZ(0);
    text-align: center;

    &-item {
        display: block;
        justify-content: center;
        text-align: center;
        color: $body-font-color;
        font-weight: $font-bold;
        transition: all 0.3s;
        font-size: 45px;

        &.active::after {
            content: "";
            display: block;
            margin: auto;
            height: 3px;
            width: 35%;
            top: 5px;
            background: $nav-colour;

        }

        &:active {
            color: $primary-colour;
        }

        &:focus {
            border: none;

        }

        &:after {
            content: "";
            display: block;
            margin: auto;
            height: 3px;
            width: 0;
            top: 5px;
            background: transparent;
            transition: all 0.3s;
        }

        &:hover::after {
            width: 35%;
            background: $nav-colour;
        }
    }

 


}