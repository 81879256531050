$primary-colour: #8A5E34;
$primary-colour-black: #000;
$second-colour: #F6EEE6;
$line-colour: #707070;
$nav-colour: #A37343;
$dark-brown-colour: #A27342;
$regular-brown-colour: #C79A6B;
$light-brown-colour: #E2BC95;
$click-colour: #C9C9C9;
$unactive-colour: #E3E3E3;

//Offers
$list-items: #AFAFAF;

$columnsDiamond: 3;

