$s: 0;
$max: 100;

@while $s <=$max {
    .u-padding-top-#{$s} {
        padding-top: rem-calc($s) !important;
    }

    .u-padding-bottom-#{$s} {
        padding-bottom: rem-calc($s) !important;
    }

    .u-padding-left-#{$s} {
        padding-left: rem-calc($s) !important;
    }

    .u-padding-right-#{$s} {
        padding-right: rem-calc($s) !important;
    }

    $s: $s+5;
}

@each $size,
$width in $breakpoints {
    @include breakpoint($size) {
        $s: 0;

        @while $s <=$max {
            .u-padding-top-#{$s}\@#{$size} {
                padding-top: rem-calc($s) !important;
            }

            .u-padding-bottom-#{$s}\@#{$size} {
                padding-bottom: rem-calc($s) !important;
            }

            .u-padding-left-#{$s}\@#{$size} {
                padding-left: rem-calc($s) !important;
            }

            .u-padding-right-#{$s}\@#{$size} {
                padding-right: rem-calc($s) !important;
            }

            .u-padding-#{$s}\@#{$size} {
                padding: rem-calc($s) !important;
            }

            $s: $s+5;
        }
    }
}