html {
    box-sizing: border-box;
    font-size: $base-font-size;
    width: 100%;
    min-height:100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    scroll-behavior: auto;
    overflow-y: hidden;
    
}

body {
    background: $body-background;
    font-family: $body-font-family;
    font-weight: $global-weight-normal;
    line-height: $global-lineheight;
    color: $body-font-color;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0rem;
    overflow-x: hidden;
}


img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

:focus {
    outline: 2px solid $primary-colour;
}
::selection {
    background: $primary-colour;
    color: #ffffff;
}
::-moz-selection {
    background: $primary-colour;
    color: #ffffff;
}
strong{
    font-weight: $font-bold;
}

.c-preloader{
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 22222222;
    background-color: #ffffff;
    &__image{
        position: absolute;
        top: 50vh;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    // &__circle{
    //     animation: a .5s linear infinite;
    //     border: 5px solid #cfc6c0;
    //     border-bottom-color: #000;
    //     border-radius: 50%;
    //     height: 40px;
    //     left:48%;
    //     position: absolute;
    //     top: 55vh;
    //     transform: translate(-50%, -50%);
    //     transition: all .7s ease-in-out;
    //     width: 40px
    // }

    
}
.spinner-square {
    display: flex;
    flex-direction: row;
    width: 60px;
    height: 90px;
    position: absolute;
    top: 60vh;
    left: 50%;
    transform: translate(-50%, -60%);
}

.spinner-square > .square {
    width: 17px;
    height: 50px;
    margin: auto auto;
    border-radius: 4px;
}

.square-1 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}

.square-3 {
    animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@keyframes square-anim {
    0% {
        height: 50px;
        background-color: $primary-colour;
    }
    20% {
        height: 50px;
    }
    40% {
        height: 90px;
        background-color: $light-brown-colour
    }
    80% {
        height: 50px;
    }
    100% {
        height: 50px;
        background-color: $primary-colour
    }
}