.c-career {
    &__title {
        height: auto;
        margin-top: 0px;
        margin-left: 0px;
        background-image: url(../images/career/line.png);
        background-repeat: no-repeat;

        &_text {
            font-weight: $font-bold;
        }

        &_paragraph {
            font-size: 17px;
        }
    }

    &__joblist {
        list-style: none;
        margin: 0px;

        &_item {
            border-bottom: 1px solid #707070;
            font-size: 16px;
            margin: 0px;

            &::after {
                content: url(../images/career/arrow1.svg);
                float: right;
                transition: all 0.5s;
            }

            &:hover {
                background-color: $primary-colour;
                color: $body-background;
                cursor: pointer;

            }

            &:hover::after {
                color: $body-background;
                content: url(../images/career/arrow1s.svg);
            }

            &.active {
                background-color: $primary-colour;
                color: $body-background;

                &::after {
                    color: $body-background;
                    content: url(../images/career/arrow1s.svg);
                }
            }
        }
    }

    &__details {
        opacity: 0;
        display: none;

        &.active {
            display: block;
            opacity: 1;
        }

        &__title {
            font-size: 25px;
            font-weight: $font-bold;
        }

        &__paragraph {
            font-size: 18px;
        }

        &__list {
            font-size: 18px;
            font-weight: $font-bold;

            &_item {
                font-weight: $font-light;

                &::before {
                    content: "\2022";
                    color: #A27342;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                    margin-left: -1em;
                }
            }
        }

        &__button {
            font-weight: 300;
            font-family: $font-family-monospace;
            font-size: 25px;
            background-color: $body-font-color;

            &:hover {
                background-color: $primary-colour;

            }
        }
    }
}