$columns: 12;
$gutter: 20;

.o-container-full {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

}

.o-container {
    max-width: rem-calc($global-width);
    margin-left: auto;
    margin-right: auto;
    padding-left: rem-calc($gutter/2);
    padding-right: rem-calc($gutter/2);
    position: relative;
}

.o-row {
    display: flex;
    margin-left: 0px;
    margin-right: 0px;
    // margin-right: - rem-calc($gutter/2);
    // margin-left: - rem-calc($gutter/2);
    flex-wrap: wrap;

    &__column {
        width: calc(100% - #{rem-calc($gutter)});
        flex: 0 0 auto;
        margin-left: rem-calc($gutter/2);
        margin-right: rem-calc($gutter/2);
    }

    @for $i from 1 through $columns {
        &__column--span-#{$i} {
            width: calc(#{$i/$columns*100 + '%'} - #{rem-calc($gutter)});
        }

        &__column--span-hidden {
            display: none;
        }
    }

    @each $size,
    $width in $breakpoints {
        @include breakpoint($size) {
            @for $i from 1 through $columns {
                &__column--span-#{$i}\@#{$size} {
                    width: calc(#{$i/$columns*100 + '%'} - #{rem-calc($gutter)});
                }

                &__column--span-hidden\@#{$size} {
                    display: none;
                }

                &__column--span-visible\@#{$size} {
                    display: block;
                }
            }
        }
    }
}