.c-solutions{
    &__title{
        font-weight: $font-bold;
        
    }
    &__bg{
        background-image: url('../images/solutions/bg.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__contact{
        font-weight: 300;
        font-family: $font-family-monospace;
        font-size: 25px;
        width: 277px;
        background-color: $body-font-color;
        &:hover{
            background-color: $primary-colour;

        }
    }
}