.c-contact {
    &__lines {
        width: 10vw;
    }

    &__title {
        font-weight: $font-bold;

        &_h1 {
            font-weight: $font-bold;
        }

    }

    &__container {
        -webkit-box-shadow: 0px -1px 10px 0px rgba(187, 187, 187, 1);
        -moz-box-shadow: 0px -1px 10px 0px rgba(187, 187, 187, 1);
        box-shadow: 0px -1px 10px 0px rgba(187, 187, 187, 1);
        border-radius: 15px;
    }

    &__button {
        font-weight: 300;
        font-family: $font-family-monospace;
        font-size: 25px;
        background-color: $body-font-color;

        &:hover {
            background-color: $primary-colour;

        }
    }
}

#notice,
#noticeError {
    display: none;
}