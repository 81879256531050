
div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, h7, pre, form, p, blockquote, th, td {
    margin: 0;
    padding: 0;
}
p, h4 {
    margin-bottom: $paragraph-margin-bottom;
    font-size: inherit;
    line-height: $paragraph-lineheight;
    font-family: $body-font-family-nobold;
    font-weight: normal;
}

a {   
    line-height: inherit;
    cursor: pointer;
    color: $primary-colour-black;
    text-decoration: none;
    word-break: break-all;
    word-break: break-word;
    &:visited {
        border-bottom: 1px solid;

    }
    &:active {   
        color: $primary-colour;   
    }
    &:focus {   
        border: none; 
        
    }
}


h1, h2, h3, h5, h6 {
    font-family: $body-font-family;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
    color: $header-color;
    line-height: $header-lineheight;
    margin-bottom: $header-margin-bottom;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    small {
        line-height: 0;
        color: $header-small-font-color;
    }
}
@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            $font-size-temp: 1rem;
            #{$header} {
                @if map-has-key($header-defs, font-size) {
                    $font-size-temp: rem-calc(map-get($header-defs, font-size));
                    font-size: $font-size-temp;
                }
            }
        }
    }
}
blockquote {
    font-style: italic; 
    margin: 0 0 $paragraph-margin-bottom;
    padding: rem-calc(20 40 20 40);
    font-family: $font-family-serif;
    &, p {
        line-height: $paragraph-lineheight;
    }
}

ul, ol, dl {
    margin-bottom: $paragraph-margin-bottom;
    list-style-position: outside;
    line-height: $paragraph-lineheight;
}

li {
    font-size: inherit;
    margin-bottom: 0.5rem;
}

ul {
    margin-left: 1.25rem;
    list-style-type: disc;
}

ol {
    margin-left: 1.25rem;
}

ul, ol {
    & & {
        margin: 1rem 0 1rem 1.5rem;
        font-size: 90%;
    }
}

dl {
    margin-bottom:  1rem;
    dt {
      margin-bottom: 0.2rem;
      font-weight: 600;
    }
    dd {
        margin-bottom: 0.8rem;
    }
}

abbr, abbr[title] {
   
    cursor: help;
    text-decoration: none;
}

figure {
    margin: 0;
}

pre {
    font-family: $font-family-monospace;
    margin-bottom: 2.5rem;
    font-size: 1rem; 
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
    > code {
        display: block;
        padding: 1rem 1.5rem;
        white-space: pre;
    }
}
code {
    padding: rem-calc(2 5 1);
    border: none;
    border: 1px solid $primary-colour;
    border-radius: 4px;
    font-family: $font-family-monospace;
    font-size: 1rem; 
    overflow: auto;
}

table {
    border-collapse: collapse;
    margin-bottom: $paragraph-margin-bottom;
}
tr {
    border-bottom: 1px solid $primary-colour;
}
th, td {
    text-align: left;
    padding: rem-calc(10px);
}